export const CLOSE_ICON = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAHqADAAQAAAABAAAAHgAAAADKQTcFAAAB9ElEQVRIDbXXv0rDQBgA8LsmaXFydHDRQZycBEFsm4CToA/hMzhE2oClNlUnX8NFUNBFVDAFwcFBEAefQHAUdGjN+X2xCRd7Se/S86DN/f1+bXJ/CPU8b54Qsk3D8GW/2z2B/L+lRqMxUy6X31utVki9ZvMVpIVIo/So0+ns6pZd1522LOucMGZD7EfTsuolyMwlEGMu3IHDpKwhE6GmeTVEMeLyYDAAmDE/FV8jnqCErHDGp2maz0bQ693VbXsKGqpcYxXrgiC45uqUshnoF6F0q91uPxkYDQGdeA66CXPoFs0I1onLoClYBy6LjsCT4CqoEC6Cq6KZsApeBM2FZfCiKMam+DUuRbsZbCypfowdw5pcgzp+c8B1miyZVP8/hWQ5/alPFYXrnNJV6DTLdZRGcYwUjB2FODb8JiUUh+AhIZ36/f4BdH4TDDiLdyRBm7BK+h9zE2lREGlJdW+XgjmUn0jf8AP4O6Z0sIyFM1B8phvwwdte6FTLhXPQaMlkTDipf54Jj0Pj51wUF8Ky6CT4CKyKFsVTcFG0CJ7Ak6KqeATrQlVwQzcqgVdgJdwYjuNcwtlYiwfAVXnD58amshlLrWY7zkUJ0HWutzY0jhm9EsGrUVzGaxiGFYRPh5Ufsoc4H0QmjzgjZAfeWh7guuf7/v0PPABqIH0KOYoAAAAASUVORK5CYII='

export const UNCHECK_ICON = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAGqADAAQAAAABAAAAGgAAAABMybYKAAABU0lEQVRIDb2W3W7CMAxGSYGhwTahXcB4/4fj52JCgwEaG+U7IZRCKaIlniVTcOzvJCRN4ho3LE3Trpr78p7claSmin/L5865VUnO9WIBnlQwkAOqYoBmAv5cFhV6KciLkj7kyWXynb93ypsItsznn4EEeVMjkBgG7OsolPVakGcFh8eGCM9h0PRSHqQAz5H8bIQPwtAaBe1sHt4VbD0ofK0cTbQbSSCyhK2sD4O/jFWWzZUBzTP4qPqu1OlLF1CnTmXFmg6gdsWiOultQLi1+cXAlmFtO0bza02BAWjzD6ANoNIzJGIHVoDYzi3nCe1loq2cL3O5lXHy+sUA4FNusSjQRPvwDoVRjQlEtnHQPr2sCqwFmUQEccKi6a1w0GlLf1ULJy0LpY4x51NBFvniAohGwexvQfleCGh7r8vDwggZOZcXdvomMdmffCtf62/iEnnT9mdgYC3rwFgGAAAAAElFTkSuQmCC'

export const CHECKED_ICON = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAGqADAAQAAAABAAAAGgAAAABMybYKAAAEJklEQVRIDaVWa0xcVRCeOXe3wralDzW1r4WiUWPbaKKxBgSC1rSJ6SOV8Kf+NJZKNTwkqT+0G/nhoy2Q2gckpv/8Q1utmhgbicij2pJqYgxqjNIFsYTUKljdpSz3jt9cOMvyqIX1JPfOzJn5Zu6Zc+7MYfqPUd8R3szELwjRZjzfwbQb8h8KEZLlIOuZaCOeFsjHKwv6WlQ324DNzHG4I/dew+4JeFvJTIdH4s7p/U/1DM+0JHrzs9wlGZluiQhVE9OAJ87e6oKen6bbzgjU0J79jDA1Mknt0Fjf8UgxjU0HzSZHWimQFQiXs/CrcLqnorD3TKrdlED1ndm7sYq38GyvLOz9JtUQacxloTx89WpPzCh0A8xeF9LVM8WuPZxPzKdgV1P5eO97VpcM1NC2rlDYe1/ELa4q6tf98EddR85W7Ih+ZZ6dS6XYuy+JuLaqIPqpna9rW7OR2WllMbsqii6367wf6FjrnYtGA5nfi5gXqwqjH6qi6dLDwVj82lEEeV7lWw6md4eXLSqPrO/W1VJde84OrPidBWPxB8qLr/5tdHI0ENqPdH1ug0SETGzk6qk5B1EnQs8t+fN6s2JV9H3BZyIYekVlc/DcioUwKiPXRHRCx9LOcA0J7xiX5vEGxsdaCHziNJZpxkxgYcZOnLKvKoujUdU3XFi3Qohfs7bzpYpVH4ob98nnE07mToPN3GpEPrIOvYT3LPiQldOgIUl4uy2OxfvYM7zF4Mg+RORctAok+GnL/w+6fRLrXNQYunFhcaXPKrDC+yyfNhXaYLETvrM1UBZyOWQVoHek8OmxTLdb4ITvxRror/rWnKVWAfp7Cp8eK3TNArUWgr+ugX5jh8NWQSw/J/l0GaYfLDTjNskG329wtL8mcjdZBX6tZCmZnJsnx/zJJMLdpDGM8eScsNlmFRzgk+BjVk6DxiZ8+FD1rTFMIn7jAxLJwz7lqKbiscuDaBGv+1ZpvIS5Vn0odNyn5Afd+FlTs2XwHzbURI4XsX5XDfQdwl75xdXOzYkCs+ZK9GDSNugdQONsTBbVYCL2Bur4Ew2da/0frbSU3OFli0sB0DTOdZxUjGIV4PsSetL3DXlqPzLeWfHcoun9CKmMwDblwEBKDulCwT4wox8Z5wsR3oUq3qamyUAqTHTYt1HNt03vsEfOr73b9TgPm7uaRdBzeMAxbtdL+b/+olg76tFhmfk0TtrLs3bYFMMStOITemfAXh2zqbD6m9HmZnL6V4b3+XcGlr0VBX3oZ5NjyorstN6CHHIbUffuwtyhGyPOmZvdgo5cuCfLTSRKYFuNv3/QJadsTrcgG0yp3uuQ3H1ognqv+xZT3YbELy/Yl+W4y23Alz6IE9qCdB+d970uNZjyTZdWhWLxBY8i7/dDtHVxCOX/x1DmaNeeR67c8gf/F5D9pCUYzueLAAAAAElFTkSuQmCC'

export const RIGHT_ARROW = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAYCAYAAADOMhxqAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAAGAAAAACGbMsfAAAB4UlEQVQ4EYVSu0oDQRSdnY0PRAsfoGgl+EAEFREThJ2gIMZCK2PlD1hYCZK4FsHNJqmt/AFBBEFQ8AGKZFFiCsXGH1AEbUQQG+OO54Zd2SWvgcs8zjn33Jm5iq7rFwpjnYG6uvlEIvHKagwO8iw4I4Wfn3wsFmutwWdc4TzkkHpUVc3DpbGaiBuGcccUZZpIcOuDUw6iQCURJyCZTF5DtOCQRiGyqgoc0YlkbNkhhvAYV+VEqvfQsqwnEQ6/4GwR5fUKTRvPWta+l+MTEJDNZh80IT4hiKDMQSFEL0RHrqhEQACccsj+DQE9+VhYiDaIzggrKyAAhFsQ67HUEEGU2gJ3+uTqY2tzcwdOa0WWlBsVHdw0cDpF9gbsNSqx+A8uWGm2bfvAxWoK8B8TXFHuHMFj1TvE4/EBlfMHkJsQb4Xf36GKDuinbpApM5HfA4XCZCaT+Sh7aWRuZ1I+gtiB+ELLjBrp9DPWrMQBmZs55zlgXQhpSxk2TbNIJoGvjaPRqErtjYv1EajYtpZKp+9p7Q6fw2B//w2AYQK5lBGUQXvf+HfY0vVLIEFCUfPSdip17mM6m6ID3poaa4bOQF5BzYcOXjJxZD5GzXOEYF4Fea+E5TkghykEPd26YZq7Hqzs8g+8PpsocnMsUQAAAABJRU5ErkJggg=='

export const LEFT_ARROW = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAfCAYAAADqUJ2JAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAAHwAAAABiOXbkAAAC1klEQVRIDaVVTWgTQRTe2cxGrQh68KR4swXt2YvJLlajRGjwL0jwJhQvIgqSJruJ7iHb5Gi9KIgggh6MiFIQk0MNSfFgBQVRbBAPasFDRdqDiNvN+s12Qjcmu9nog+HNm/d937yZeZsQ4T9M07Q0EYTLGFdC/6hD8po2DW4OY7NAyArEBjNd18OrpnkHrBRnLlLLkukgMhAZMk3zEXY/wnjwH0KSFNMN42vgo2Wz2e2CbVdBVvjmL6kkHYL4NxaLfNHX6ZOTu0Ki2ABoHwPaglCByAGILLWJfYVUVR1dpfQFCCMOiZD7kiQlIPKzLcK872XjeSMAzAC31SHZ9nRhauoS5iiq0zwryuVyCYhUAXdEwNQgchFxlwiT7HnZeVU9i9w9jDBGC30yYRjGdcw9rasiHEe1CbkNBsv9wvanCoUCi33NfUesW6+BeIEzljFPoJK6rwJPOkJ4Adatd7F22lkn5LNlWfFisfg+iAjDhNLp9Bb4J1BMcNJb1iM4ziceB3I0LEm3gIw5aEIa+ATGC4axHIjtAomoZP3Cbdt9Zy5Y/6loWtY5wGY5NCJROoeX29Gf2okQS6XSD9xJHL3ygKdG4efQkLs7of6R+yh/P/8Snj+O53/lL7GW7ejseqPxLCrLJtQPIj0En5IVZb5er/d9wQ4hpt2AKdHoFxx1HOFGjBTiBWzyjuW9rEuIAUF6jUreYHoMYwNETyL+jsrmWb6X9RRiQJAWlEikBpETCDdhHFVkWcQmNcy7zH3ZXUm2kM/n99qtVgVTpyXwADebzeb5crlsuQl9hRgY3+JOyzQrENnjkG37IQ2Hz2D9d1sskBADZzKZbZTSGfwB7OfkWfTfcYitsHj98+BZL+c0LqWHIfSYY8bwi/Hc+XfBQuCK2hskk8nQyPDwDcQTbA3H/Qg3NrAQIzPDJ6SjuqtrkVD1fH4O8HRojxoadRHtEUM1Tz2Bgyb+ADNc5eOe+tGvAAAAAElFTkSuQmCC'

export const ADD_ICON = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAHKADAAQAAAABAAAAHAAAAABkvfSiAAACCklEQVRIDb2WsU4cMRCG2RMRCkgcBUqdjo4CUV0LD0IPb0CJeIS8CA8BHUUaREdBRcWBgCChXL5vmSG3sLtAAv6lf73rGc9vr+2xq5k3YDKZDHBbgyO4AodQjOEpPITHVVX9pvx3ILQM9+A5fA366Lvcp1i1GWlk/Tbch4vhc0fpSH7Ci6j7RrkKHfnXqLui3IU/GPEk6roLxObhAUyc8LIFF7paaQsffRPGmO9qU9frAI+ixQPlLvzS22jKqG+0sa0wVrsohgrmyK5535yK9a5X20JjCGO+nDoqd7QCe9crhn1N9vXCGDBHutPwxeBqHEPhhHcC+xz8FZzrdMRgLCiM/Xf18uFyFk5675xhX9IxsPSKoHOaC2mv9qViAHOfbfUF0IbvmwXD3xUu1BgYYN0vcAs7l352BJ/3CrpljC3WTVluWnHIRr15fP24Z8Q0YYjRLA9zozCDNECPXBSZQdKWedTvIT5Zn+UdIvf5EaWxN+CKghkg01XtQyCXvT3rW4lntXPzcU/bEaLHU9UZe+gvLQpH6BEjTMRPsIf01NG3/dKzcPxOme2jaqbtl2bssYKeZ8Ks30DMRWM+ns3ZGJ/LRqP2j4x9WnxbKFh24/sHEC2X2kKwbPIO0XLHUwiWPYBDtNwVQ0HBAvrUS9TLu8ajaLlrYj3MeDDaMhfhaVHfTQ4Unh7/fdX/A4juWLT6Ogj9AAAAAElFTkSuQmCC'