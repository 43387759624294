<script>
  import { CHECKED_ICON, UNCHECK_ICON } from '../utils/icon'
  export let checkedImage = CHECKED_ICON;
  export let uncheckedImage = UNCHECK_ICON;
  export let checked = false;
  export let className = ''
  export let onToggle = () => {};

  // function toggle() {
  //   checked = !checked;
  // }
</script>

<style>
  .checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    width: 26px;
    height: 26px;
  }

  .checkbox img {
    width: 100%;
    height: 100%;
  }
</style>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="checkbox {className}" on:click={onToggle}>
  <img src={checked ? checkedImage : uncheckedImage} alt="" />
</div>
