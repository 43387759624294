import { getInstance } from "../sdk";
import { toast } from "./toast";

let header = {};

let predefinedBody = {
  game_id: "",
  channel_id: 1,
};

export function setRequestHeader(key, value) {
  header[key] = value;
}

export function setPredefinedBody(key, value) {
  predefinedBody[key] = value;
}

export class HTTP {
  // GET 请求
  static get(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.timeout = 30000;
      xhr.open("GET", url);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            resolve(xhr.responseText);
          } else {
            reject(new Error(`Request failed with status ${xhr.status}`));
          }
        }
      };
      xhr.send();
    });
  }

  // POST 请求
  static post(url, data, customHeader = {}) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.timeout = 30000;
      xhr.open("POST", url);
      xhr.setRequestHeader("Content-Type", "application/json");
      const requestHeader = { ...header, ...customHeader };
      // console.log(header, customHeader);
      Object.keys(requestHeader).forEach((key) => {
        xhr.setRequestHeader(key, requestHeader[key]);
      });
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            let res = {};
            try {
              res = JSON.parse(xhr.responseText);
            } catch (error) {
              reject(error);
            }

            if (res.code === 1) {
              resolve(res);
            } else if (res.code === 401) {
              toast.show("登录已过期, 请重新登录");
              getInstance().reset();
              reject(new Error(res.msg));
            } else {
              reject(new Error(res.msg));
            }
          } else {
            reject(new Error(`Request failed with status ${xhr.status}`));
          }
        }
      };
      xhr.send(
        JSON.stringify({
          ...predefinedBody,
          ...data,
        })
      );
    });
  }
}
