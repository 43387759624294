import { HTTP } from "./utils/request";

const baseUrl = 'https://api.jcyyx.com/sdkApi/';

// 登录
export async function login(data) {
  console.log(data)
  const url = `${baseUrl}login`;
  return HTTP.post(url, data);
}

// 注册
export async function register(data) {
  const url = `${baseUrl}reg`;
  return HTTP.post(url, data);
}

// 登出
export async function logout(data, header = {}) {
  const url = `${baseUrl}logout`;
  return HTTP.post(url, data, header);
}

// 自动登录
export async function refreshToken(data, header = {}) {
  const url = `${baseUrl}refreshToken`;
  return HTTP.post(url, data, header);
}

// 小号列表
export async function smallAccount(data, header = {}) {
  const url = `${baseUrl}getTrumpetList`;
  return HTTP.post(url, data, header);
}

// 新增小号
export async function addSmallAccount(data, header = {}) {
  const url = `${baseUrl}addTrumpet`;
  return HTTP.post(url, data, header);
}

// 小号登录
export async function smallAccountLogin(data, header = {}) {
  const url = `${baseUrl}smallAccountLogin`;
  return HTTP.post(url, data, header);
}

// paylist
export async function payList(data, header = {}) {
  const url = `${baseUrl}payList`;
  return HTTP.post(url, data, header);
}

// pay
export async function pay(data, header = {}) {
  const url = `${baseUrl}addOrder`;
  return HTTP.post(url, data, header);
}