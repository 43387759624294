<script>
  import { onMount } from "svelte";
  import { toast } from "../utils/toast";
  import { CLOSE_ICON, ADD_ICON, LEFT_ARROW, RIGHT_ARROW } from "../utils/icon";
  import { smallAccount, addSmallAccount, smallAccountLogin } from "../api";
  import { _ } from "svelte-i18n";
  import Checkbox from "../components/Checkbox.svelte";
  export let visible = false;
  export let onClose;
  export let sdk = {};
  export let onChangeAccount = () => {};
  export let onChangeSmallAccount = () => {};

  let boxUser = {};
  let gameUsers = [];
  let checkedGameUserIndex = 0;
  let lastLoginIndex = 0;
  let defalutLoginIndex = 0;

  let addSmallAccountVisible = false;
  let addNickname = "";

  $: if (visible) {
    fetchAccounts();
  }

  function fetchAccounts() {
    smallAccount({
      game_id: sdk.getConfig()?.game_id,
    }).then(({ data }) => {
      boxUser = data?.box_user;
      gameUsers = data?.game_user;
    });
  }

  function handleChangeSmallAccount(index) {
    checkedGameUserIndex = index;
    const smallAccount = gameUsers[index];
    
    smallAccountLogin({
      trumpet_user_id: smallAccount?.id,
    }).then(({ data }) => {
      onChangeSmallAccount(data);
    });
  }

  function handleChangeAccount() {
    onChangeAccount();
  }

  function handleAddAccount() {
    addSmallAccountVisible = true;
    visible = false;
  }

  function back() {
    addSmallAccountVisible = false;
    visible = true;
  }

  function handleAddAcountSubmit() {
    if (!addNickname) {
      toast.show($_("please_enter_game_account_name"));
      return;
    }
    addSmallAccount({
      game_id: sdk.getConfig()?.game_id,
      name: addNickname,
    }).then(() => {
      addSmallAccountVisible = false;
      visible = true;
      fetchAccounts();
    });
  }
</script>

{#if visible}
  <div class="modal">
    <!-- <button class="close" on:click={onClose}>
      <img class="close-icon" src={CLOSE_ICON} alt="" srcset="" />
    </button> -->
    <h2 class="modal-title">{$_("account_list")}</h2>
    <div class="modal-content">
      <div class="box-user">
        <div class="box-user-info">{boxUser.username}</div>
        <button class="box-user-change-btn" on:click={handleChangeAccount}
          >{$_("switch_account")}</button
        >
      </div>
      <div class="game-user-list">
        {#each gameUsers as user, index}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <div
            class="game-user"
            on:click={() => handleChangeSmallAccount(index)}
          >
            <Checkbox checked={checkedGameUserIndex === index} />
            <div class="game-user-info">{user.nickname}</div>
            {#if lastLoginIndex === index}
              <div class="last-login">（{$_("last_login")}）</div>
            {/if}
            <div
              class="default-login"
              class:active={defalutLoginIndex === index}
            >
              {$_("default")}
            </div>
            <img class="right-arrow" src={RIGHT_ARROW} alt="" />
          </div>
        {/each}
      </div>

      <button class="add-small-account-btn" on:click={handleAddAccount}>
        <img class="add-icon" src={ADD_ICON} alt="" />
        {$_("add_account")}
      </button>
    </div>
  </div>
{/if}

{#if addSmallAccountVisible}
  <div class="modal">
    <button class="back" on:click={back}>
      <img class="back-icon" src={LEFT_ARROW} alt="" srcset="" />
    </button>
    <h2 class="modal-title">{$_("add_account")}</h2>
    <div class="modal-content">
      <div class="add-tips">{$_("create_game_account_name")}</div>
      <input
        class="input"
        type="text"
        id="username"
        name="username"
        placeholder={$_("please_enter_game_account_name")}
        bind:value={addNickname}
      />
      <button class="submit-btn" on:click={handleAddAcountSubmit}
        >{$_("confirm")}</button
      >
    </div>
  </div>
{/if}

<style lang="scss" scoped>
  .modal {
    width: 630px;
    max-height: 690px;
    background: rgba(0, 0, 0, 0.85);
    border-radius: 40px;
    opacity: 0.85;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 50px;

    .close {
      position: absolute;
      top: 30px;
      right: 40px;
      height: 30px;
      width: 30px;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .close-icon {
        height: 30px;
        width: 30px;
      }
    }

    .modal-title {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 36px;
      color: #ffffff;
      line-height: 1;
      text-align: center;
      margin-top: 60px;
      margin-bottom: 45px;
    }

    .modal-content {
      width: 530px;
      margin: 0 auto;

      .box-user {
        display: flex;
        flex-direction: column;
        align-items: center;
        .box-user-info {
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 42px;
          color: #8fc31f;
          line-height: 1;
        }

        .box-user-change-btn {
          // width: 164px;
          height: 52px;
          background: #8fc31f;
          border-radius: 26px;

          font-family: PingFang SC;
          font-weight: 500;
          font-size: 26px;
          color: #ffffff;

          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          margin-top: 30px;

          border: none;
        }
      }

      .game-user-list {
        margin-top: 46px;

        .game-user {
          width: 530px;
          height: 88px;
          border-radius: 44px;
          border: 1px solid rgba(143, 195, 31, 0.4);
          box-sizing: border-box;

          display: flex;
          align-items: center;

          padding: 0 20px;

          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }

          .game-user-info {
            margin-left: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 30px;
            color: #ffffff;
            white-space: nowrap;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .last-login {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 22px;
            color: #7c7b7b;
            opacity: 0.5;
            flex: none;
          }

          .default-login {
            // width: 60px;
            height: 30px;
            padding: 0 8px;
            border-radius: 8px;
            border: 1px solid #ffffff;
            opacity: 0.3;

            font-family: PingFang SC;
            font-weight: 500;
            font-size: 22px;
            color: #7c7b7b;

            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 7px;

            flex: none;

            &.active {
              border-color: rgba(143, 195, 31, 1);
              color: rgba(143, 195, 31, 1);
              opacity: 1;
            }
          }

          .right-arrow {
            width: 12px;
            height: 24px;
            margin-left: auto;
            padding-left: 10px;
          }
        }
      }

      .add-small-account-btn {
        display: flex;
        border: none;
        background: transparent;
        margin: 0 auto;
        margin-top: 32px;
        width: fit-content;

        font-family: PingFang SC;
        font-weight: 500;
        font-size: 26px;
        color: #ffffff;
        line-height: 1;

        align-items: center;
        justify-content: center;

        .add-icon {
          width: 28px;
          height: 28px;
          margin-right: 10px;
        }
      }
    }
  }

  .modal {
    .back {
      position: absolute;
      top: 30px;
      left: 40px;
      height: 30px;
      width: 30px;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .back-icon {
        height: 31px;
        width: 18px;
      }
    }

    .add-tips {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #ffffff;
      opacity: 0.5;
    }

    .input {
      width: 540px;
      height: 88px;
      background: rgba(247, 247, 247, 0.25);
      border-radius: 20px;

      font-family: PingFang SC;
      font-weight: 500;
      font-size: 30px;
      color: rgba(255, 254, 254, 1);
      line-height: 55px;
      border: none;

      padding: 0 40px;
      margin-top: 30px;

      &::placeholder {
        color: #787575;
      }
    }

    .submit-btn {
      width: 540px;
      height: 88px;
      background: #8fc31f;
      border-radius: 44px;
      border: none;
      margin: 0;
      margin-top: 80px;

      font-family: PingFang SC;
      font-weight: bold;
      font-size: 36px;
      color: #ffffff;
    }
  }
</style>
