import "./i18n/index";
import { logout as logoutApi, refreshToken as refreshTokenApi } from "./api";
import { setPredefinedBody, setRequestHeader } from "./utils/request";
import { ModalManager, MODAL_MAP } from "./modal";
import { setupI18n } from "./i18n/index";
import ls from "local-storage";
import qs from "qs";

const USERINFO_STORAGE_KEY = "01_gameshop_sdk_userinfo";

/**
 * SDKManager类用于管理SDK操作
 */
class SDKManager {
  /**
   * 构造函数，创建SDKManager的实例
   */
  constructor() {
    this.loginPromise = {
      // 登录promise
      resolve: () => {},
      reject: () => {},
    };
    this.smallAccountPromise = {
      // 小号登录promise
      resolve: () => {},
      reject: () => {},
    };
    this.payPromise = {
      // 支付promise
      resolve: () => {},
      reject: () => {},
    }
    this.hasInit = false; // 是否已经初始化
    this.originalQuery = {}; // 原始的query
    this.config = {
      // 默认配置
      game_id: "", // 游戏id
      channel_id: 1,
    };
    this.hasLogin = false; // 是否登录
    this.userInfo = null; // 用户信息
    this.smallAccountInfo = null; // 小号信息

    this.modalManager = new ModalManager(this); // 模态框管理
  }

  /**
   * 获取SDKManager的单例
   * @returns {SDKManager} SDKManager的实例
   */
  static getInstance() {
    if (!SDKManager.instance) {
      SDKManager.instance = new SDKManager();
    }
    return SDKManager.instance;
  }

  /**
   * 初始化SDK
   * @param {Object} config 初始化配置
   * @returns {Promise} 返回初始化Promise
   */
  init(config = {}) {
    return new Promise((resolve, reject) => {
      if (this.hasInit) {
        reject(new Error("sdk has already been initialized"));
        return;
      }
      if (typeof config !== "object") {
        reject(new Error("config must be an object"));
        return;
      }

      const query = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });

      this.originalQuery = query; // 原始的query

      config.game_id = config.game_id || query.game_id;

      config.channel_id = config.channel_id || query.channel_id;

      if (!config.game_id) {
        reject(new Error("game_id is required"));
        return;
      }

      this.config = { ...this.config, ...config };

      console.log(
        `init sdk, game_id: ${this.config.game_id}, channel_id: ${this.config.channel_id}`
      );

      this.hasInit = true;

      this.config.game_id && setPredefinedBody("game_id", this.config.game_id);
      this.config.channel_id &&
        setPredefinedBody("channel_id", this.config.channel_id);

      setupI18n({ withLocale: config.locale || "en" }).then(() => {
        resolve();
      });
    });
  }

  /**
   * 自动登录
   * @returns {Promise} 返回自动登录Promise
   */
  autoLogin() {
    return new Promise((resolve, reject) => {
      if (!this.hasInit) {
        reject(new Error("sdk has not been initialized"));
        return;
      }

      let storageUserInfo = ls(USERINFO_STORAGE_KEY);
      if (!storageUserInfo) {
        if (!this.originalQuery.token) {
          reject(new Error("user has not logged in"));
          return;
        }

        storageUserInfo = {
          refresh_token: this.originalQuery.token, // url传进来的token是refresh_token
        };
      }

      this.userInfo = storageUserInfo;

      if (this.isLoading) {
        console.warn("auto login is in progress");
        return;
      }

      this.isLoading = true;

      refreshTokenApi(
        {
          game_id: this.config.game_id,
        },
        {
          Authorization: `Bearer ${this.userInfo.refresh_token}`,
        }
      )
        .then((res) => {
          if (res.data.access_token) {
            this.userInfo.access_token = res.data.access_token;
          }
          if (res.data.refresh_token) {
            this.userInfo.refresh_token = res.data.refresh_token;
          }
          ls(USERINFO_STORAGE_KEY, this.userInfo);

          setRequestHeader(
            "Authorization",
            `Bearer ${this.userInfo.access_token}`
          );

          this.hasLogin = true;

          this.smallAccountPromise = {
            resolve,
            reject,
          };

          this.modalManager.openModal(MODAL_MAP.SMALL_ACCOUNT);

          // resolve(this.userInfo);
        })
        .catch(reject)
        .finally(() => {
          this.isLoading = false;
        });
    });
  }

  /**
   * 登录
   * @returns {Promise} 返回登录Promise
   */
  login() {
    return new Promise((resolve, reject) => {
      if (!this.hasInit) {
        reject(new Error("sdk has not been initialized"));
        return;
      }

      // this.loginPromise = {
      //   resolve,
      //   reject,
      // };
      this.smallAccountPromise = {
        resolve,
        reject,
      };

      this.modalManager.openModal(MODAL_MAP.LOGIN);
    });
  }

  /**
   * 注册
   * @returns {Promise} 返回注册Promise
   */
  register() {
    return new Promise((resolve, reject) => {
      if (!this.hasInit) {
        throw new Error("sdk has not been initialized");
      }

      // this.loginPromise = {
      //   resolve,
      //   reject,
      // };

      this.smallAccountPromise = {
        resolve,
        reject,
      };

      this.modalManager.openModal(MODAL_MAP.REGISTER);
    });
  }

  /**
   * 登出
   * @returns {Promise} 返回登出Promise
   */
  logout() {
    return new Promise((resolve, reject) => {
      if (!this.hasInit) {
        reject(new Error("sdk has not been initialized"));
        return;
      }
      if (!this.hasLogin) {
        reject(new Error("user has not logged in"));
        return;
      }

      logoutApi(
        {
          game_id: this.config.game_id,
        },
        {
          Authorization: `Bearer ${this.userInfo.access_token}`,
        }
      )
        .then(() => {
          this.hasLogin = false;
          this.userInfo = null;

          ls(USERINFO_STORAGE_KEY, null);
          resolve();
        })
        .catch(reject);
    });
  }

  /**
   * 重置SDK状态
   */
  reset() {
    this.hasLogin = false;
    this.userInfo = null;

    ls(USERINFO_STORAGE_KEY, null);
    this.modalManager.closeModal()
  }

  /**
   * 小号登录
   * @returns {Promise} 返回小号登录Promise
   */
  smallAccount() {
    return new Promise((resolve, reject) => {
      if (!this.hasInit) {
        reject(new Error("sdk has not been initialized"));
        return;
      }
      if (!this.hasLogin) {
        reject(new Error("user has not logged in"));
        return;
      }

      this.smallAccountPromise = {
        resolve,
        reject,
      };

      this.modalManager.openModal(MODAL_MAP.SMALL_ACCOUNT);
    });
  }

  /**
   * 支付
   * @param {Object} params 支付参数
   * @returns {Promise} 返回支付Promise
   */
  pay(params) {
    return new Promise((resolve, reject) => {
      if (!this.hasInit) {
        reject(new Error("sdk has not been initialized"));
        return;
      }
      if (!this.hasLogin) {
        reject(new Error("user has not logged in"));
        return;
      }
      if (!params) {
        reject(new Error("params is required"));
        return;
      }
      this.payPromise = {
        resolve,
        reject,
      };
      this.modalManager.openModal(MODAL_MAP.PAY, params);
    });
  }

  /**
   * 获取SDK配置
   * @returns {Object} 返回SDK配置
   */
  getConfig() {
    return this.config;
  }

  /**
   * 检查用户是否已登录
   * @returns {boolean} 返回用户是否已登录
   */
  isLoggedIn() {
    return this.hasLogin;
  }

  /**
   * 获取用户信息
   * @returns {Object|null} 返回用户信息
   */
  getUserInfo() {
    return this.smallAccountInfo;
  }
}

/**
 * 获取SDKManager的实例
 * @returns {SDKManager} SDKManager的实例
 */
export const getInstance = () => {
  return SDKManager.getInstance();
};
