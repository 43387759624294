import './toast.css';
class Toast {
  constructor() {
    this.container = null;
  }

  show(message, duration = 3000) {
    if (!this.container) {
      this.container = document.body;
    }
    const toast = document.querySelector('.toast') || document.createElement('div');
    toast.className = 'toast';
    toast.innerText = message;

    this.container.appendChild(toast);

    // Force a reflow to apply the 'show' class
    window.getComputedStyle(toast).opacity;
    toast.classList.add('show');

    setTimeout(() => {
      toast.classList.remove('show');
      toast.classList.add('hide');

      toast.addEventListener('transitionend', () => {
        toast.remove();
      });
    }, duration);
  }
}

export const toast = new Toast();