<script>
  import { onMount } from "svelte";
  import { toast } from "../utils/toast";
  import { CLOSE_ICON, ADD_ICON, LEFT_ARROW, RIGHT_ARROW } from "../utils/icon";
  import { payList, pay } from "../api";
  import { _ } from "svelte-i18n";
  import Checkbox from "../components/Checkbox.svelte";
  export let visible = false;
  export let couponSelectVisible = false;
  export let childPaymentVisible = false;
  export let onClose;
  export let sdk = {};
  export let params = {
    money: 0,
    goods_id: "",
    goods_name: "",
    goods_desc: "",
    server_num: "",
    server_name: "",
    role_id: "",
    role_name: "",
    cp_data: "",
    device: "",
  };
  export let onPayFinish = () => {};
  export let onPayFail = () => {};

  let payListData = {
    discount: 0,
    pay_list: [],
    ptb_money: 0,
    voucherList: [],
  };

  let selectedPayMethodId = 0; // 选中的支付方式id
  let selectedPayMethodName = "";
  let selectedCouponIdx = -1; // -1 为不使用代金券

  let payLoading = false;
  let childPaymentList = [];

  $: selectedCouponValue =
    selectedCouponIdx === -1
      ? $_("no_voucher")
      : `-${payListData.currency}${payListData.voucherList[selectedCouponIdx].face_value}`; // 使用代金券金额

  $: discountValue = (params.money - params.money * payListData.discount) / 100;

  $: totalPay =
    ((params.money -
      (selectedCouponIdx === -1
        ? 0
        : payListData.voucherList[selectedCouponIdx].face_value)) *
      payListData.discount) /
    100;
  $: totalDiscount = params.money / 100 - totalPay;

  onMount(() => {
    fetchPayList();
  });

  function fetchPayList() {
    payList({
      game_id: sdk.getConfig()?.game_id,
      currency: params?.money,
    }).then(({ data }) => {
      console.log(data);
      payListData = {
        ...data,
      };
    });
  }

  function handlePay() {
    if (selectedPayMethodId === 0) {
      // toast.show($_("please_select_pay_method"));
      return;
    }

    if (payLoading) {
      return;
    }

    payLoading = true;

    pay({
      game_id: sdk.getConfig()?.game_id,
      pay_id: selectedPayMethodId,
      voucher_collection_id:
        selectedCouponIdx === -1
          ? 0
          : payListData.voucherList[selectedCouponIdx].id,
      money: params?.money,
      goods_id: params?.goods_id,
      goods_name: params?.goods_name,
      goods_desc: params?.goods_desc,
      server_num: params?.server_num,
      server_name: params?.server_name,
      role_id: params?.role_id,
      role_name: params?.role_name,
      cp_data: params?.cp_data,
      device: params?.device,
      // pay_method: selectedPayMethodId,
    })
      .then((res) => {
        console.log(res);
        onPayFinish(res);
      })
      .catch((err) => {
        onPayFail(err);
      })
      .finally(() => {
        payLoading = false;
      });
    // onPayFinish();
  }

  function handleSelectPayMethod(item) {
    if (item.child && item.child.length) {
      selectedPayMethodName = item.title;
      selectedPayMethodId = 0;
      childPaymentList = item.child;
      childPaymentVisible = true;
      visible = false;
    } else {
      selectedPayMethodId = item.pay_id;
    }
  }

  function handleOpenCouponSelect() {
    couponSelectVisible = true;
    visible = false;
  }

  function handleSelectCoupon(index) {
    selectedCouponIdx = index;

    back();
  }

  function back() {
    selectedPayMethodId = 0;
    couponSelectVisible = false;
    childPaymentVisible = false;
    visible = true;
  }
</script>

{#if visible}
  <div class="modal">
    <button class="close" on:click={onClose}>
      <img class="close-icon" src={CLOSE_ICON} alt="" srcset="" />
    </button>
    <h2 class="modal-title">{$_("order_payment")}</h2>
    <div class="modal-content">
      <div class="pay-info">
        <div class="pay-info-item">
          <div class="label">{$_("order_amount")}</div>
          <div class="value">
            <span class="price"
              >{payListData.currency}{params?.money / 100}</span
            >
          </div>
        </div>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div class="pay-info-item" on:click={handleOpenCouponSelect}>
          <div class="label">{$_("coupon")}</div>
          <div class="value" class:no-select={selectedCouponIdx === -1}>
            {selectedCouponValue}
          </div>
          <img class="right-icon" src={RIGHT_ARROW} alt="" />
        </div>
        {#if payListData.discount < 1}
          <div class="pay-info-item">
            <div class="label">
              <span>{$_("discount_offer")}</span>
              <div class="discount">{payListData.discount}折</div>
            </div>
            <div class="value">-{payListData.currency}{discountValue}</div>
          </div>
        {/if}
      </div>
      <div class="pay-method">
        {#each payListData.pay_list as item, index}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <div
            class="pay-method-item"
            on:click={() => handleSelectPayMethod(item)}
          >
            <img class="icon" src={item.icon} alt="" />
            <div class="method-content">
              <div class="method-name">{item.title}</div>
              <div class="method-desc">{item.desc}</div>
            </div>
            <div class="checkbox1">
              <Checkbox checked={selectedPayMethodId === item.pay_id} />
            </div>
          </div>
        {/each}
      </div>
      <div class="pay-footer">
        <div class="total">
          <div class="total-pay">
            <div class="label">{$_("payment_amount")}：</div>
            <div class="value">{payListData.currency}{totalPay}</div>
          </div>
          {#if totalDiscount > 0}
            <div class="total-discount">
              <div class="label">{$_("total_offer")}：</div>
              <div class="value">-{payListData.currency}{totalDiscount}</div>
            </div>
          {/if}
        </div>
        <button class="pay-submit-btn" on:click={handlePay}
          >{$_("confirm_payment")}</button
        >
      </div>
    </div>
  </div>
{/if}

{#if couponSelectVisible}
  <div class="coupon-modal">
    <button class="back" on:click={back}>
      <img class="back-icon" src={LEFT_ARROW} alt="" srcset="" />
    </button>
    <h2 class="modal-title">{$_("select_voucher")}</h2>
    <div class="modal-content">
      <div class="coupon-list">
        {#each payListData.voucherList as item, index}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <div
            class="coupon-item"
            class:disabled={item.mi_charge > params.money / 100}
            on:click={() => handleSelectCoupon(index)}
          >
            <div class="info">
              <div class="end-time">{item.usage_end_time}{$_("expiry")}；</div>
              <div class="content">{item.content}；满{item.mi_charge}可用</div>
              <div class="game-limit">仅限{item.game_name}使用；</div>
            </div>
            <div class="price">￥{item.face_value}</div>
            <div class="checkbox2">
              <Checkbox checked={selectedCouponIdx === index} />
            </div>
          </div>
        {/each}
      </div>
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <div class="no-use" on:click={() => handleSelectCoupon(-1)}>
        {$_("no_voucher")}
      </div>
      <!-- <button class="submit-btn" on:click={handleAddAcountSubmit}>添加</button> -->
    </div>
  </div>
{/if}

{#if childPaymentVisible}
  <div class="child-payment-modal">
    <button class="back" on:click={back}>
      <img class="back-icon" src={LEFT_ARROW} alt="" srcset="" />
    </button>
    <h2 class="modal-title">{selectedPayMethodName}</h2>
    <div class="modal-content">
      <div class="payment-list">
        {#each childPaymentList as item, index}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <div
            class="payment-item"
            class:disabled={item.mi_charge > params.money / 100}
            on:click={() => handleSelectPayMethod(item)}
          >
            <div class="child-method-content">
              <div class="child-method-name">{item.title}</div>
            </div>
            <div class="checkbox3">
              <Checkbox checked={selectedPayMethodId === item.pay_id} />
            </div>
          </div>
        {/each}
      </div>
      <button class="submit-btn" on:click={handlePay}
        >{$_("confirm_payment")} {payListData.currency}{totalPay}</button
      >
    </div>
  </div>
{/if}

<style lang="scss" scoped>
  .modal {
    width: 630px;
    // max-height: 680px;
    background: rgba(0, 0, 0, 0.85);
    border-radius: 40px;
    opacity: 0.85;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // padding-bottom: 50px;

    .close {
      position: absolute;
      top: 30px;
      right: 40px;
      height: 30px;
      width: 30px;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .close-icon {
        height: 30px;
        width: 30px;
      }
    }

    .modal-title {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 36px;
      color: #ffffff;
      line-height: 1;
      text-align: center;
      margin-top: 60px;
      margin-bottom: 45px;
    }

    .modal-content {
      .pay-info {
        width: 540px;
        margin: 0 auto;

        .pay-info-item {
          display: flex;
          // justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          line-height: 1;

          &:last-of-type {
            margin-bottom: 0;
          }

          .label {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 30px;
            color: #ffffff;

            display: flex;
            align-items: center;

            line-height: 1;
          }

          .discount {
            // width: 68px;
            height: 30px;
            border-radius: 6px;
            border: 1px solid #ff0000;

            font-family: PingFang SC;
            font-weight: 500;
            font-size: 24px;
            color: #ff0000;

            display: flex;
            align-items: center;
            justify-content: center;

            padding: 0 6px;

            margin: 0;
            margin-left: 16px;
          }

          .value {
            font-size: 30px;
            margin-left: auto;

            color: rgba(255, 0, 0, 1);

            .price {
              color: rgba(143, 195, 31, 1);
            }

            &.no-select {
              color: rgba(152, 159, 165, 1);
            }
          }

          .right-icon {
            height: 24px;
            width: 12px;
            margin-left: 15px;
          }
        }
      }

      .pay-method {
        margin-top: 38px;
        max-height: calc(88px * 3);
        overflow-y: auto;
        .pay-method-item {
          width: 100%;
          display: flex;
          align-items: center;
          height: 88px;
          border-top: 1px solid #e2e4e6;
          padding: 0 40px;
          box-sizing: border-box;

          &:last-of-type {
            border-bottom: 1px solid #e2e4e6;
          }

          .icon {
            width: 60px;
            height: 60px;
          }

          .method-content {
            margin-left: 20px;
            line-height: 1;

            .method-name {
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 30px;
              color: #ffffff;
            }

            .method-desc {
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 20px;
              color: #989fa5;
              margin-top: 11px;
            }
          }

          .checkbox1 {
            margin-left: auto;

            :global(.checkbox) {
              width: 36px;
              height: 36px;
            }
          }
        }
      }

      .pay-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 144px;
        padding: 0 40px;
        box-sizing: border-box;

        .total {
          line-height: 1;

          .total-pay {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 30px;
            color: #ffffff;
            display: flex;
            align-items: center;

            .label {
              // width: min-content;
              max-width: 130px;
              min-width: fit-content;
            }
            // white-space: nowrap;
          }

          .total-discount {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 24px;
            color: #ffffff;
            display: flex;
            align-items: center;
            margin-top: 10px;
          }

          .value {
            color: rgba(255, 0, 0, 1);
          }
        }

        .pay-submit-btn {
          // width: 210px;
          // width: min-content;
          max-width: 260px;
          padding: 0 34px;
          height: 88px;
          background: #8fc31f;
          border-radius: 44px;
          margin: 0;
          border: none;
          flex: none;

          font-family: PingFang SC;
          font-weight: bold;
          font-size: 36px;
          color: #ffffff;

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .coupon-modal {
    width: 630px;
    // max-height: 680px;
    background: rgba(0, 0, 0, 0.85);
    border-radius: 40px;
    opacity: 0.85;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .modal-title {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 36px;
      color: #ffffff;
      line-height: 1;
      text-align: center;
      margin-top: 60px;
      margin-bottom: 45px;
    }

    .back {
      position: absolute;
      top: 30px;
      left: 40px;
      height: 30px;
      width: 30px;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .back-icon {
        height: 31px;
        width: 18px;
      }
    }

    .coupon-list {
      width: 590px;
      margin: 0 auto;
      .coupon-item {
        width: 590px;
        height: 154px;
        background: #f4faff;
        border-radius: 20px;
        border: 1px solid #e2e4e6;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        padding: 28px 20px;

        &.disabled {
          opacity: 0.28;
          pointer-events: none;
        }

        .info {
          line-height: 1;
          .end-time {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 28px;
            color: #000000;
          }

          .content,
          .game-limit {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 24px;
            color: #989fa5;
            margin-top: 13px;
          }
        }

        .price {
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 42px;
          color: #ff0000;
          margin-left: auto;
        }

        .checkbox2 {
          margin-left: 37px;

          :global(.checkbox) {
            width: 36px;
            height: 36px;
            border: 1px solid rgba(200, 204, 209, 1);
            border-radius: 50%;
          }
        }
      }
    }

    .no-use {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #989fa5;
      margin-top: 30px;
      margin-bottom: 37px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .child-payment-modal {
    width: 630px;
    // max-height: 680px;
    background: rgba(0, 0, 0, 0.85);
    border-radius: 40px;
    opacity: 0.85;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .modal-title {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 36px;
      color: #ffffff;
      line-height: 1;
      text-align: center;
      margin-top: 60px;
      margin-bottom: 45px;
    }

    .back {
      position: absolute;
      top: 30px;
      left: 40px;
      height: 30px;
      width: 30px;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .back-icon {
        height: 31px;
        width: 18px;
      }
    }
    .payment-list {
      width: 630px;
      margin: 0 auto;
      max-height: 350px;
      overflow-y: auto;

      .payment-item {
        height: 88px;
        padding: 0 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(226, 228, 230, 1);

        .child-method-name {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 30px;
          color: #ffffff;
        }

        .checkbox3 {
          :global(.checkbox) {
            width: 36px;
            height: 36px;
          }
        }
      }
    }

    .submit-btn {
      width: 540px;
      height: 88px;
      background: #8fc31f;
      border-radius: 44px;

      font-family: PingFang SC;
      font-weight: bold;
      font-size: 36px;
      color: #ffffff;

      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px auto;

      border: none;
    }
  }
</style>
