<script>
  import { _ } from 'svelte-i18n'
  import { CLOSE_ICON } from "../utils/icon";
  export let visible = false;
  export let onClose = () => {};
  export let onLogin = () => {};
  export let openModal = () => {};

  let username = "";
  let password = "";

  function login() {
    onLogin({
      username,
      password,
    });
  }

  function openRegisterModal() {
    openModal("register");
  }
</script>

{#if visible}
  <div class="modal">
    <!-- <button class="close" on:click={onClose}>
      <img class="close-icon" src={CLOSE_ICON} alt="" srcset="" />
    </button> -->
    <h2 class="modal-title">{$_('account_login')}</h2>
    <form class="form" action="" on:submit|preventDefault>
      <input
        class="input"
        type="text"
        id="username"
        name="username"
        placeholder="{$_('please_enter_account')}"
        bind:value={username}
      />
      <input
        class="input"
        type="password"
        id="password"
        name="password"
        placeholder="{$_('please_enter_password')}"
        bind:value={password}
      />
      <button class="submit-btn" on:click={login}>{$_('sign_in')}</button>
    </form>
    <p class="register-tips">
      {$_('no_account_register')}?<button on:click={openRegisterModal}>{$_('go_to_register')}</button>
    </p>
  </div>
{/if}

<style lang="scss" scoped>
  .modal {
    width: 630px;
    height: 620px;
    background: rgba(0, 0, 0, 0.85);
    border-radius: 40px;
    opacity: 0.85;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .close {
      position: absolute;
      top: 30px;
      right: 40px;
      height: 30px;
      width: 30px;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .close-icon {
        height: 30px;
        width: 30px;
      }
    }

    .modal-title {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 36px;
      color: #ffffff;
      line-height: 1;
      text-align: center;
      margin-top: 60px;
      margin-bottom: 45px;
    }

    .form {
      width: 540px;
      margin: 0 auto;

      .input {
        width: 540px;
        height: 88px;
        background: rgba(247, 247, 247, 0.25);
        border-radius: 20px;

        font-family: PingFang SC;
        font-weight: 500;
        font-size: 30px;
        color: rgba(255, 254, 254, 1);
        line-height: 55px;
        border: none;

        margin-bottom: 20px;
        padding: 0 40px;
        box-sizing: border-box;

        &:last-of-type {
          margin-bottom: 0;
        }

        &::placeholder {
          color: #787575;
        }
      }

      .submit-btn {
        width: 540px;
        height: 88px;
        background: #8fc31f;
        border-radius: 44px;
        border: none;
        margin: 0;
        margin-top: 80px;

        font-family: PingFang SC;
        font-weight: bold;
        font-size: 36px;
        color: #ffffff;
      }
    }

    .register-tips {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: #7c7b7b;
      line-height: 1;

      margin-left: 45px;
      margin-top: 40px;

      button {
        color: rgba(255, 255, 255, 1);
        text-decoration: underline;
        margin: 0 10px;
        border: none;
        font-size: 24px;
        background-color: transparent;
      }
    }
  }
</style>
