import { register, init, getLocaleFromNavigator } from "svelte-i18n";

// register('en', () => fetch('/i18n/en.json').then((res) => res.json()));
// register('zh-CN', () => fetch('/i18n/zh_CN.json').then((res) => res.json()));
// register('zh-TW', () => fetch('/i18n/zh_TW.json').then((res) => res.json()));
// register('ko', () => fetch('/i18n/ko.json').then((res) => res.json()));

// console.log(getLocaleFromNavigator())

// init({
//   fallbackLocale: 'en',
//   initialLocale: getLocaleFromNavigator(),
// });

import { dictionary, locale, _ } from "svelte-i18n";

const fallbackLocale = "zh-CN";
const MESSAGE_FILE_URL_TEMPLATE = "https://01sdk.tangkunhou.com/i18n/{locale}.json";

let cachedLocale;

function setupI18n({ withLocale: _locale } = { withLocale: "zh-CN" }) {
  const messsagesFileUrl = MESSAGE_FILE_URL_TEMPLATE.replace(
    "{locale}",
    _locale
  );

  return fetch(messsagesFileUrl)
    .then((response) => response.json())
    .then((messages) => {
      dictionary.set({ [_locale]: messages });

      cachedLocale = _locale;

      locale.set(_locale);
    })
    .catch((error) => {
      console.error(error);
      if (_locale !== fallbackLocale) {
        return setupDefaultLocale();
      } else {
        throw error;
      }
    });
}

function setupDefaultLocale() {
  return setupI18n({ withLocale: fallbackLocale });
}

export { _, locale, setupI18n };
