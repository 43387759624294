import "./base.scss";
import { writable } from "svelte/store";
import { toast } from "./utils/toast";
import { login as loginApi, register as registerApi } from "./api";
import { setRequestHeader } from "./utils/request";
import ls from "local-storage";
import LoginModal from "./views/LoginModal.svelte";
import RegisterModal from "./views/RegisterModal.svelte";
import SmallAccountModal from "./views/SmallAccountModal.svelte";
import PayModal from "./views/PayModal.svelte";

const USERINFO_STORAGE_KEY = "01_gameshop_sdk_userinfo";

export const MODAL_MAP = {
  LOGIN: "login",
  REGISTER: "register",
  SMALL_ACCOUNT: "smallAccount",
  PAY: "pay",
};

export class ModalManager {
  constructor(sdk) {
    this.sdk = sdk;
    this.loginVisible = writable(false);
    this.registerVisible = writable(false);
    this.smallAccountVisible = writable(false);
    this.payVisible = writable(false);
    this.couponSelectVisible = writable(false);
    this.childPaymentVisible = writable(false);

    this.loginInstance = null;
    this.registerInstance = null;
    this.smallAccountInstance = null;
    this.payInstance = null;
  }

  openModal(modal, data) {
    this.closeModal();
    switch (modal) {
      case MODAL_MAP.LOGIN:
        this.openLoginModal();
        break;
      case MODAL_MAP.REGISTER:
        this.openRegisterModal();
        break;
      case MODAL_MAP.SMALL_ACCOUNT:
        this.openSmallAccountModal();
        break;
      case MODAL_MAP.PAY:
        this.openPayModal(data);
        break;
      default:
        break;
    }
  }

  closeModal(modal) {
    if (!modal) {
      this.loginVisible.set(false);
      this.registerVisible.set(false);
      this.smallAccountVisible.set(false);
      this.payVisible.set(false);
      this.couponSelectVisible.set(false);
      this.childPaymentVisible.set(false);
    } else {
      switch (modal) {
        case MODAL_MAP.LOGIN:
          this.loginVisible.set(false);
          break;
        case MODAL_MAP.REGISTER:
          this.registerVisible.set(false);
          break;
        case MODAL_MAP.SMALL_ACCOUNT:
          this.smallAccountVisible.set(false);
          break;
        case MODAL_MAP.PAY:
          this.payVisible.set(false);
          this.couponSelectVisible.set(false);
          this.childPaymentVisible.set(false);
          break;
        default:
          break;
      }
    }
  }

  openLoginModal() {
    this.loginVisible.set(true);

    if (!this.loginInstance) {
      this.loginInstance = new LoginModal({
        target: document.body,
        props: {
          visible: true,
          onClose: () => {
            this.loginVisible.set(false);
            this.sdk.smallAccountPromise.reject(new Error("用户取消登录"));
          },
          openModal: (modal) => this.openModal(modal),
          onLogin: ({ username, password }) => {
            loginApi({
              username,
              password,
              pattern: "account",
              game_id: this.sdk.config.game_id,
            })
              .then((res) => {
                this.sdk.hasLogin = true;
                this.sdk.userInfo = res.data;
                ls(USERINFO_STORAGE_KEY, res.data);

                setRequestHeader(
                  "Authorization",
                  `Bearer ${this.sdk.userInfo.access_token}`
                );

                // this.sdk.loginPromise.resolve(res.data);
                this.openModal(MODAL_MAP.SMALL_ACCOUNT);
                this.loginVisible.set(false);
              })
              .catch((error) => {
                toast.show(error.message);
                this.sdk.smallAccountPromise.reject(new Error(error.message));
              });
          },
        },
      });
    } else {
      this.loginInstance.$set({ visible: true });
    }

    this.loginVisible.subscribe((value) => {
      if (!value && this.loginInstance) {
        this.loginInstance.$set({ visible: false });
      }
    });
  }

  openRegisterModal() {
    this.registerVisible.set(true);

    if (!this.registerInstance) {
      this.registerInstance = new RegisterModal({
        target: document.body,
        props: {
          visible: true,
          onClose: () => {
            this.registerVisible.set(false);
            this.sdk.smallAccountPromise.reject(new Error("用户取消注册"));
          },
          openModal: (modal) => this.openModal(modal),
          onRegister: ({ username, password }) => {
            registerApi({
              username,
              password,
              pattern: "account",
              game_id: this.sdk.config.game_id,
            })
              .then((res) => {
                this.sdk.hasLogin = true;
                this.sdk.userInfo = res.data;

                ls(USERINFO_STORAGE_KEY, res.data);

                setRequestHeader(
                  "Authorization",
                  `Bearer ${this.sdk.userInfo.access_token}`
                );

                // this.sdk.loginPromise.resolve(res.data);
                this.openModal(MODAL_MAP.SMALL_ACCOUNT);
                this.registerVisible.set(false);
              })
              .catch((error) => {
                toast.show(error.message);
                this.sdk.smallAccountPromise.reject(new Error(error.message));
              });
          },
        },
      });
    } else {
      this.registerInstance.$set({ visible: true });
    }

    this.registerVisible.subscribe((value) => {
      if (!value && this.registerInstance) {
        this.registerInstance.$set({ visible: false });
      }
    });
  }

  openSmallAccountModal() {
    this.smallAccountVisible.set(true);

    if (!this.smallAccountInstance) {
      this.smallAccountInstance = new SmallAccountModal({
        target: document.body,
        props: {
          visible: true,
          sdk: this.sdk,
          onClose: () => {
            this.smallAccountVisible.set(false);
            this.sdk.smallAccountPromise.reject(new Error("用户取消选择"));
          },
          onChangeAccount: () => {
            this.sdk.reset()
            this.openModal(MODAL_MAP.LOGIN);
          },
          onChangeSmallAccount: (account) => {
            this.sdk.smallAccountInfo = account;
            this.sdk.userInfo.access_token = account.access_token;
            this.sdk.userInfo.refresh_token = account.refresh_token;

            ls(USERINFO_STORAGE_KEY, this.sdk.userInfo); // 小号登录之后要更新本地存储的用户信息

            setRequestHeader(
              "Authorization",
              `Bearer ${this.sdk.userInfo.access_token}`
            );

            this.sdk.smallAccountPromise.resolve({
              token: account.access_token,
              game_user: account.game_user,
            });
            this.smallAccountVisible.set(false);
          },
        },
      });
    } else {
      this.smallAccountInstance.$set({ visible: true });
    }

    this.smallAccountVisible.subscribe((value) => {
      if (!value && this.smallAccountInstance) {
        this.smallAccountInstance.$set({ visible: false });
      }
    });
  }

  openPayModal(params) {
    this.payVisible.set(true);
    this.childPaymentVisible.set(true);

    if (!this.payInstance) {
      this.payInstance = new PayModal({
        target: document.body,
        props: {
          visible: true,
          childPaymentVisible: false,
          sdk: this.sdk,
          params,
          onClose: () => {
            this.payVisible.set(false);
          },
          onPayFinish: (res) => {
            if (
              !res.data.payment_success &&
              res.data.open_with &&
              res.data.pay_info
            ) {
              window.open(res.data.pay_info, "_blank");
            }
            this.payVisible.set(false);
            this.sdk.payPromise.resolve(res.data);
          },

          onPayFail: (res) => {
            this.sdk.payPromise.reject(new Error(res.message));
            // this.payVisible.set(false);
          },
        },
      });
    } else {
      this.payInstance.$set({ visible: true, params });
    }

    this.payVisible.subscribe((value) => {
      console.log("value", value, !value && this.payInstance);
      if (!value && this.payInstance) {
        this.payInstance.$set({ visible: false });
      }
    });

    this.childPaymentVisible.subscribe((value) => {
      console.log("value", value, !value && this.payInstance);
      if (!value && this.payInstance) {
        this.payInstance.$set({ childPaymentVisible: false });
      }
    });

    this.couponSelectVisible.subscribe((value) => {
      if (!value && this.payInstance) {
        this.payInstance.$set({ couponSelectVisible: false });
      }
    });

    // this.openModal(MODAL_MAP.PAY);
  }
}
